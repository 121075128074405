.instagram {

    padding: 10px 5px 40px;

}






       
       

