.footer{
    position: absolute; 
    bottom: 0; 
    text-align: center; 
    background-color: rgb(0, 175, 0); 
    width: 100%; 
    color: white; 
    padding-top: 10px; 
} 

.footer p {
    margin: 0; 
    padding-left: 10px; 
    padding-bottom: 10px; 
    Font-Family: 'Raleway', Sans-Serif;
}

.footer iframe {
    padding-bottom: 10px; 
margin:0; 

}



