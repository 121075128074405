.home {
    background-color: rgb(248, 248, 147); 
    padding-left: 30px; 
    padding-right: 30px; 
    padding-bottom: 120px; 
}

.home .intro {
    padding-top: 10px; 
    margin-top: 10px; 
    text-align: center;  
}

@media only screen and (min-width: 500px) {
.events-facilities {
    display: flex; 
    justify-content: space-around; 

}

}

@media only screen and (min-width: 500px) {
    .home .intro {
        padding-left: 20px; 
        padding-right: 20px; 
        padding-top: 20px; 
    }
    
    }

    @media only screen and (min-width: 780px) {
        .home .intro {
            padding-left: 50px; 
            padding-right: 50px; 
            padding-top: 30px;
            font-size: 20px;  
        }

        .events-facilities {
            margin-left: 10%; 
            margin-right: 10%; 
        }
        
        }
       
        @media only screen and (min-width: 950px) {
            .home .intro {
                padding-left: 100px; 
                padding-right: 100px;

 
            }
            .events-facilities {
                margin-left: 20%; 
                margin-right: 20%; 
            }
            
            }

