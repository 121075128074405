.facebook {
    background-color: rgb(248, 248, 147);
    padding: 10px 5px 40px;

}

.facebook .iframe1, .facebook .iframe2 {
    display: flex; 
    justify-content: center;     
}

.facebook p {
    text-align: center; 
    margin-bottom: 0; 
}


.facebook .fbsymbol {
    height: 90px; 
    display: flex; 
    justify-content: center; 
}

@media only screen and (min-width: 520px) {
    .facebook .iframe1 {
        display: none; 
    }

}

@media only screen and (max-width: 520px) {
    .facebook .iframe2 {
        display: none; 
    }

}




       
       

