.calendar {
    background-color: rgb(248, 248, 147);
    padding-bottom: 40px;  
}

.calendar iframe {
    margin-top: 10px; 
margin-left: 2%; 
margin-bottom: 50px; 
width: 96%;  

}

.calendar h2 {
    text-align: center; 
    margin: 20px 0 10px; 
    padding-top: 10px; 

}

@media only screen and (min-width: 1030px) {
    .calendar iframe {
    width: 80%;  
    margin-left: 10%; 

    
    }
}


       
       

